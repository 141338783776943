import React from 'react'
import Layout from '../../../templates/Main'
import Feed from '../../../modules/Feed'
import Heading from '../../../modules/Heading'
import { connect } from 'react-redux'

const InvoicePage = ({ params, bigText }) => {
    return (
        <Layout id={params.id}>
            <Heading size='h1' text='Your Invoices' bigText={bigText} />
            <Feed type="invoice" user='client' />
        </Layout>
    )
}

function mapStateToProps(state) {
    return {
        bigText: state.Vehicle.bigText,
    };
}

export default connect(mapStateToProps)(InvoicePage)

