import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { verifyAuth } from '../../models/Users'

export default (ChildComponent) => {
    const ComposedComponent = ({ dispatch, isVerifying, isAuthenticated, ...props }) => {
        const [output, setOutput] = useState(null)

        useEffect(() => {
            verifyAuth(dispatch)
        }, [])

        useEffect(() => {
            if (isVerifying && !isAuthenticated) {
                // Some kind of loading here
            } else if (!isVerifying && isAuthenticated) {
                setOutput(<ChildComponent {...props} />)
            }
        }, [isAuthenticated, isVerifying])


        return output
    }

    function mapStateToProps(state) {
        return {
            isAuthenticated: state.Auth.isAuthenticated,
            isVerifying: state.Auth.isVerifying,
        }
    }
    return connect(mapStateToProps)(ComposedComponent)
}
